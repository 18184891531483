import React from "react"
import { Helmet } from "react-helmet"

const Head = () => {
  return (
    <>
      <Helmet>
        <link href="https://fonts.gstatic.com" rel="preconnect" />
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300&display=swap"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="//cdn.jsdelivr.net/gh/kenwheeler/slick@1.9.0/slick/slick.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="//cdn.jsdelivr.net/gh/kenwheeler/slick@1.9.0/slick/slick-theme.css"
        />
        <title>石山アンジュ|Official Website</title>
        {/* ogp */}
        <meta
          name="description"
          content="石山アンジュの公式ウェブサイト1989年生まれ。「シェア(共有)」の概念に親しみながら育つ。シェアリングエコノミーを通じた新しいライフスタイルを提案する活動を行うほか、政府と民間のパイプ役として規制緩和や政策推進にも従事。2018年10月ミレニアル世代のシンクタンク一般社団法人Public
                  Meets Innovationを設立。 ほかNewsPicks「WEEKLY
                  OCHIAI」レギュラーMC出演を務めるなど幅広く活動。世界経済フォーラム
                  Global Future Council Japan
                  メンバー。新しい家族の形「拡張家族」を掲げるコミュニティ一般社団法人Cift代表理事。著書に「シェアライフ-新しい社会の新しい生き方（クロスメディア・パブリッシング）」がある。2012年国際基督教大学(ICU)卒。新卒で(株)リクルート入社、その後(株)クラウドワークス経営企画室を経て現職。"
        />
        <meta property="og:title" content="石山アンジュ|Official Website" />
        <meta
          property="og:description"
          content="石山アンジュの公式ウェブサイト | Anju Ishiyama Official Website"
        />
        <meta property="og:url" content="https://anjuishiyama.world/" />
        <meta
          property="og:image"
          content="https://anjuishiyama.world/images/ogp.png"
        />
        <meta
          name="twitter:card"
          content="石山アンジュの公式ウェブサイト | Anju Ishiyama Official Website"
        />
        <meta property="og:type" content="website" />
        <meta name="google" content="notranslate" />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap"
          rel="stylesheet"
        />
        {/* ogp */}
      </Helmet>
    </>
  )
}

export default Head
