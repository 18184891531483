import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Button from "@material-ui/core/Button"
import Hidden from "@material-ui/core/Hidden"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import styled from "styled-components"
import WrapTypo from "../components/WrapTypo"
import Drawer from "@material-ui/core/Drawer"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Header = () => {
  const [isOpen, setOpen] = useState(false)
  const sp_mtSize = "40px"
  const data = useStaticQuery(graphql`
    query {
      menuBar: file(relativePath: { eq: "menuBotton1.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      close: file(relativePath: { eq: "close.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <Hidden smDown>
        <Box width="95%" mx={"auto"} py={"24px"}>
          <Grid container wrap="wrap">
            <Grid item xs={6}>
              <AnchorLink to="/">
                <WrapTypo isCooperItalic>
                  ANJU ISHIYAMA Official Website
                </WrapTypo>
              </AnchorLink>
            </Grid>
            {/* <Grid item xs={1}>
              <AnchorLink to="/#note" stripHash>
                <WrapTypo>NOTE</WrapTypo>
              </AnchorLink>
            </Grid> */}
            <Grid item xs={2}>
              <AnchorLink to="/profile" stripHash>
              <Box textAlign="center">
                <WrapTypo>PROFILE</WrapTypo>
              </Box>
              </AnchorLink>
            </Grid>
            <Grid item xs={2}>
              <AnchorLink to="/media" stripHash>
                <Box textAlign="center">
                  <WrapTypo>MEDIA ＆ NEWS</WrapTypo>
                </Box>
              </AnchorLink>
            </Grid>
            {/* <Grid item xs={1}>
              <AnchorLink to="/#news" stripHash>
                <WrapTypo>NEWS</WrapTypo>
              </AnchorLink>
            </Grid> */}
            <Grid item xs={2}>
              <AnchorLink to="/contacts" stripHash>
                <Box textAlign="center">
                  <WrapTypo>CONTACT</WrapTypo>
                </Box>
              </AnchorLink>
            </Grid>
          </Grid>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Box width="90%" m={"auto"} pt="32px">
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs={6}>
              <WrapTypo isCooperItalic>
                <Link to="/" style={{ color: "#717171" }}>
                  ANJU ISHIYAMA
                  <br />
                  Official Website
                </Link>
              </WrapTypo>
            </Grid>
            <Grid item xs={2}>
              {isOpen ? (
                <Button
                  style={{ width: "100%", maxWidth: "126px", margin: "auto" }}
                  onClick={() => setOpen(c => !c)}
                >
                  <Box style={{ width: "50%", maxWidth: "40px" }}>
                    <Img fluid={data.close.childImageSharp.fluid} />
                  </Box>
                </Button>
              ) : (
                <Button
                  style={{ width: "100%", maxWidth: "126px", margin: "auto" }}
                  onClick={() => setOpen(c => !c)}
                >
                  <Box style={{ width: "70%", maxWidth: "40px" }}>
                    <Img fluid={data.menuBar.childImageSharp.fluid} />
                  </Box>
                </Button>
              )}
            </Grid>
          </Grid>
          <Drawer
            anchor={"left"}
            open={isOpen}
            style={{ width: "100%" }}
            transitionDuration="1500"
          >
            <Box width="100vw" height="100vh" bgcolor="#e5e5e5">
              <Box width="90%" margin="auto" pt="32px">
                <Grid container justify="space-between" alignItems="center">
                  <Grid item xs={6}>
                    <WrapTypo isCooperItalic>
                      <Link
                        to="/"
                        style={{ color: "#717171" }}
                        onClick={() => setOpen(c => !c)}
                      >
                        ANJU ISHIYAMA
                        <br />
                        Official Website
                      </Link>
                    </WrapTypo>
                  </Grid>
                  <Grid item xs={2}>
                    {isOpen ? (
                      <Button
                        style={{
                          width: "100%",
                          maxWidth: "126px",
                          margin: "auto",
                        }}
                        onClick={() => setOpen(c => !c)}
                      >
                        <Box style={{ width: "50%", maxWidth: "20px" }}>
                          <Img fluid={data.close.childImageSharp.fluid} />
                        </Box>
                      </Button>
                    ) : (
                      <Button
                        style={{
                          width: "100%",
                          maxWidth: "126px",
                          margin: "auto",
                        }}
                        onClick={() => setOpen(c => !c)}
                      >
                        <Box style={{ width: "70%", maxWidth: "40px" }}>
                          <Img fluid={data.menuBar.childImageSharp.fluid} />
                        </Box>
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  height="80vh"
                >
                  <Box width="100%" textAlign="center">
                    <WrapTypo mt={sp_mtSize} fs="18px">
                      <Link
                        to="/profile"
                        style={{ color: "#717171" }}
                        onClick={() => setOpen(false)}
                      >
                        PROFILE
                      </Link>
                    </WrapTypo>
                    <WrapTypo mt={sp_mtSize} fs="18px">
                      <Link
                        to="/media"
                        style={{ color: "#717171" }}
                        onClick={() => setOpen(false)}
                      >
                        MEDIA ＆ NEWS
                      </Link>
                    </WrapTypo>
                    {/* <WrapTypo mt={sp_mtSize} fs="18px">
                      <Link
                        to="/#news"
                        style={{ color: "#717171" }}
                        onClick={() => setOpen(false)}
                      >
                        NEWS
                      </Link>
                    </WrapTypo> */}
                    {/* <WrapTypo mt={sp_mtSize} fs="18px">
                      <Link
                        to="/#note"
                        style={{ color: "#717171" }}
                        onClick={() => setOpen(false)}
                      >
                        NOTE
                      </Link>
                    </WrapTypo> */}
                    <WrapTypo mt={sp_mtSize} fs="18px">
                      <Link
                        to="/contacts"
                        style={{ color: "#717171" }}
                        onClick={() => setOpen(false)}
                      >
                        CONTACT
                      </Link>
                    </WrapTypo>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Drawer>
        </Box>
      </Hidden>
    </>
  )
}

export default Header
